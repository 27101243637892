import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import styled from "styled-components";
import "./style.scss";

const Contact = () => (
  <StaticQuery
    query={graphql`
      query Contact {
        site {
          siteMetadata {
            facebook
            instagram
            youtube
          }
        }
      }
    `}
    render={(data) => (
      <Section className="footer center" id="contacts">
        <div className="content has-text-centered">
          <H3 className="is-size-4">Contact</H3>
          <p className="is-size-5">
            <a href="mailto:bopsbrother@gmail.com">bopsbrother@gmail.com</a>
          </p>
          <article
            className="media has-text-centered"
            style={{ display: "inline-flex" }}
          >
            &nbsp;
            <span className="icon is-medium" style={{ marginRight: "10px" }}>
              <a
                href={data.site.siteMetadata.instagram}
                target="_blank"
                title="Instagram"
                rel="noopener noreferrer"
              >
                <FaInstagram size="fa-xs" color="#333" />
              </a>
            </span>
            &nbsp;
            <span className="icon is-medium" style={{ marginRight: "10px" }}>
              <a
                href={data.site.siteMetadata.youtube}
                target="_blank"
                title="YouTube"
                rel="noopener noreferrer"
              >
                <FaYoutube size="fa-xs" color="#333" />
              </a>
            </span>
            <span className="icon is-medium">
              <a
                href={data.site.siteMetadata.facebook}
                target="_blank"
                title="Facebook"
                rel="noopener noreferrer"
              >
                <FaFacebook size="fa-xs" color="#333" />
              </a>
            </span>
          </article>
        </div>
      </Section>
    )}
  />
);

export default Contact;

const Section = styled.footer`
  margin-bottom: 0;
`;

const H3 = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
`;
