import React from "react";
import styled from "styled-components";
import Contact from "./contact";
import Header from "./header";
import Helmet from "./helmet";
import "./style.scss";
import Player from "./player";

const Layout = ({ children }) => (
  <div>
    <Helmet />
    <Header />
    <Player />
    {/*

    <Columns
      className="columns is-gapless is-vcentered reverse-columns"
      style={{ marginBottom: 0 }}
    >
      <div className="column" style={{ background: "#262c2c", flex: 1 }}>
        <Bio />
      </div>
      <Player />
      <div className="column" style={{ background: "#e9322c", flex: 1 }}>
        <Player />
      </div>
    </Columns>
    */}
    <Contact />
  </div>
);

const Columns = styled.div``;

export default Layout;
