import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Checkout from "./checkout";

const Sounds = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "bops-soundsofparade.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <div className="container is-fullhd">
      <div className="columns p-4">
        <div className="column">
          <GatsbyImage
            image={data.file.childImageSharp.gatsbyImageData}
            //style={{ width: "100%" }}
          />
        </div>
        <div className="column">
          <h1 className="title" style={{ fontSize: "2rem" }}>
            Sounds of Parade
          </h1>
          <h2 className="subtitle" style={{ fontSize: "1.5rem" }}>
            BOPS
          </h2>
          <Checkout />
          <p style={{ fontSize: "0.8rem", marginTop: "20px" }}>
            Avec quatre singles mis en ligne comme avant-goût de ce nouvel opus
            (« Sleeplessness », « No Job », « Bouncer » et « Ravachol »), les
            rennais BOPS signent bel et bien leur retour avec le dix-titres
            Sounds of Parade, un album faussement grandiloquent mais résolument
            grandiose. Les nombreux arrangements avec notamment la présence de
            l'Orchestre Symphonique de Sofia qui sublime 3 des titres de l’album
            apportent une dimension et une richesse nouvelle au groupe. C’est
            l’un des marqueurs fort de ce nouvel opus : une amplitude de
            couleurs différentes qui surprend à chaque morceau. En ouverture,
            violons et tubas répondent aux guitares et moog dans The Flag, avant
            une plongée dans le corps de l’album aux secousses post-punk
            (Bouncer, Tomboy) et réverbes rock (Sequencer). L’étonnante
            révérence pop, la ballade symphonique Martin Martin conclut cet
            album. La violence, la politique, le genre sont au cœur des thèmes
            abordés dans les textes de cet album : Sounds of Parade, comme une
            bande-son-miroir des inquiétudes et questionnements qui nous
            habitent toutes et tous. Chaque titre est habillé par une production
            cousue au plus proche des intentions propres à chaque chanson,
            réunies par cette identité forte qui traverse l’album et que porte
            le duo de voix.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sounds;
