import React, { useState } from "react";
const buttonStyles = {
  fontSize: "13px",
  textAlign: "center",
  color: "#000",
  padding: "12px 60px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  letterSpacing: "1.5px",
  width: "200px",
  marginBottom: "5px",
};
const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
};
const Checkout = () => {
  const [loading] = useState(false);
  return (
    <>
      <ul>
        <li>
          <a
            className='button is-rounded is-normal'
            style={
              loading
                ? { ...buttonStyles, ...buttonDisabledStyles }
                : buttonStyles
            }
            href='https://buy.stripe.com/cN25lD3cx6xU87u146'
          >
            ORDER LP
          </a>
        </li>
        <li>
          <a
            className='button is-rounded is-normal'
            style={
              loading
                ? { ...buttonStyles, ...buttonDisabledStyles }
                : buttonStyles
            }
            href='https://buy.stripe.com/eVa01j28taOaafC28b'
          >
            ORDER CD
          </a>
        </li>
        <li>
          <a
            className='button is-rounded is-normal'
            style={
              loading
                ? { ...buttonStyles, ...buttonDisabledStyles }
                : buttonStyles
            }
            href='https://modulor.lnk.to/SoundOfParade'
          >
            SAVE ON SPOTIFY
          </a>
        </li>
      </ul>
      <p>Shipping within 3 days</p>
    </>
  );
};
export default Checkout;
